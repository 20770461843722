import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { scaleLinear } from 'd3-scale'
import React, { useMemo } from 'react'
import { useMeasure } from 'react-use'

const SimpleBarChart = ({ data }) => {
  const [ref, { width }] = useMeasure()
  const sortedData = useMemo(() => data.sort((a, b) => b.value - a.value), [data])
  const max = useMemo(() => Math.max(...data.map((d) => d.value)), [data])
  const scale = useMemo(() => scaleLinear().domain([0, max]).range([0, width * 0.8]), [
    max,
    width,
  ])
  return (
    <Stack ref={ref}>
      {sortedData.map((d, i) => (
        <Stack key={d.name + i}>
          <Text>{d.name}</Text>
          <HStack spacing={4}>
            <Box
              h="3"
              borderRadius="lg"
              bg="textColor"
              style={{ width: scale(d.value) }}
              flexShrink="0"
            />
            <Text fontSize="lg" color="textColor">
              {d.value}
              <Text fontSize="xs" as="span" color="text">{`(人)`}</Text>
            </Text>
          </HStack>
        </Stack>
      ))}
    </Stack>
  )
}

export default SimpleBarChart
